import { graphql } from 'gatsby';
import React, { Fragment } from 'react';
import Container from '../../components/layout/Container/Container';
import Group from '../../components/layout/Group/Group';
import PageLayout from '../../components/layout/PageLayout/PageLayout';
import PageSection from '../../components/layout/PageSection/PageSection';
import Avatar from '../../components/ui/Avatar/Avatar';
import Block from '../../components/ui/Cards/Block';
import Divider from '../../components/ui/Divider/Divider';
import PageBanner from '../../components/ui/PageBanners/PageBanner';
import Seo from '../../components/utils/Seo';
import SVGIcons from '../../components/utils/SVGIcons';
import { Direction } from '../../globals';
import './about.scss';

interface AboutProps {
    data: {
        authorsYaml: {
            authors: Author[];
        };
        file: FluidImage;
        faqYaml: {
            faq: QuestionAnswer[];
        };
    };
}
const About: React.FC<AboutProps> = ({ data }: AboutProps) => {

    console.log('data.file: ' + JSON.stringify(data.file));
    return (
        <PageLayout>
            <Seo
                isPost={false}
                title="Sobre"
                url="sobre/"
                description="Sobre Solidity na Prática"
            />
            <PageBanner className="page-banner-withcircularimage">
                <Fragment>
                    <h1>
                        Solidity na Prática
                    </h1>
                    <p>
                        Olá programador, tudo bem?
                        Seja muito bem vindo ao site Solidity na Prática. 
                        Nosso objetivo é auxiliar novos programadores a 
                        ingressarem no mundo da Web3.
                        Aqui você irá aprender a desenvolver aplicações, 
                        criar contratos inteligentes, tokens ERC20 e ERC721,
                        jogos e coleções NFT com contratos inteligentes.
                    </p>
                </Fragment>
                <Fragment>
                    <SVGIcons icon='solidity' width={256} height={256} />
                    {/* <Img
                        fluid={data.file.childImageSharp.fluid}
                        alt="Sobre Solidity na Prática"
                    ></Img> */}
                </Fragment>
            </PageBanner>

            <PageSection>
                <Container>
                    <Group size={2}>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>Sobre o conteúdo do site</h2>

                            <p>
                                Boa parte do nosso conteúdo foi traduzido do site <a href='https://solidity-by-example.org/' target='_blank'>Solidity by Example</a> e 
                                ajustado para que você possa entendê-lo da melhor forma possível.
                            </p>
                            <p>
                                Assim como também, procuro sempre estudar novos 
                                conteúdos e trazê-los de forma didática para que 
                                possamos aprender cada vez mais a programar e 
                                desenvolver aplicações de qualidade para a Web3.
                            </p>
                        </Block>
                        <Block
                            className="about-block"
                            direction={Direction.vertical}
                        >
                            <h2>Nossa missão</h2>
                            <p>
                                Aprendermos juntos a criar grandes aplicações para a Web3. 
                                Assim como eu quero aprender mais sobre Solidity, ao mesmo 
                                tempo quero trazer mais programadores para essa comunidade 
                                em crescimento. 
                                Espero de coração que todo o conteúdo postado aqui, lhe ajude 
                                de alguma maneira e acrescente mais em seu conhecimento. 
                                Quem sabe no futuro não desenvolvemos nossa própria blockchain 
                                e games NFT? Se outros conseguiram, você também pode!<br />
                                Bons estudos e vamos nessa.
                            </p>
                        </Block>
                    </Group>

                    <div className="section-text">
                        <h2>Autor</h2>
                    </div>
                    <Group size={1}>
                    <Block
                            className="about-author-block"
                            direction={Direction.vertical}
                        >
                            <h3>{data.authorsYaml.authors[0].name}</h3>
                            <Avatar image={data.authorsYaml.authors[0].image} />
                            <div className="about-author-block__socials">
                                <a
                                    href={data.authorsYaml.authors[0].website}
                                    className="about-author-block__socials__item"
                                >
                                    <SVGIcons icon='instagram' />
                                </a>
                                <a
                                    href={data.authorsYaml.authors[0].facebook}
                                    className="about-author-block__socials__item"
                                >
                                    <SVGIcons icon='facebook' />
                                </a>
                                <a
                                    href={data.authorsYaml.authors[0].twitter}
                                    className="about-author-block__socials__item"
                                >
                                    <SVGIcons icon='twitter' />
                                </a>
                                <a
                                    href={data.authorsYaml.authors[0].linkedin}
                                    className="about-author-block__socials__item"
                                >
                                    <SVGIcons icon='linkedin' />
                                </a>
                            </div>
                            <div
                                dangerouslySetInnerHTML={{
                                    __html:
                                        data.authorsYaml.authors[0]
                                            .short_description || ''
                                }}
                            ></div>
                            {/* <Link
                                className="about_team__learn-more"
                                to={`/${data.authorsYaml.authors[0].url}`}
                            >
                                Ler mais
                            </Link> */}
                        </Block>
                    </Group>
                </Container>
            </PageSection>

            {/* <PageSection className="faq-section">
                <Container>
                    <h2>FAQ</h2>
                    <Accordion items={data.faqYaml.faq} />
                </Container>
            </PageSection> */}

            <Divider direction={Direction.horizontal} />
            <Container>
            <p>
                <center><h5>Template do site desenvolvido por <a href='https://theaisummer.com/' target='_blank'>AI Summer</a> e modificado por Matheus.</h5></center>
            </p>
            <p>
                <center><h5>O site ainda passará por diversas mudanças ao longo dos dias.</h5></center>
            </p>
            </Container>

        </PageLayout>
    );
};

export const aboutQuery = graphql`
    {
        authorsYaml {
            authors {
                name
                linkedin
                twitter
                website
                image {
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            base64
                            aspectRatio
                            src
                            srcSet
                            sizes
                        }
                    }
                }
                facebook
                github
                short_description
                url
            }
        }
        file(relativePath: { eq: "icon.png" }) {
            childImageSharp {
                fluid {
                    aspectRatio
                    src
                    srcSet
                    sizes
                }
            }
        }

        faqYaml {
            faq {
                question
                answer
            }
        }
    }
`;

export default About;
